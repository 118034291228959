import { Typography, Container, List, ListItem, Grid } from "@mui/material";
import {
  StyledLink,
  StyledListHeader,
  ToolGrid,
  ToolItem,
} from "../components/ToolsElements";
import { Description } from "../components/HomeElements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/styles.css";

function Tools() {
  return (
    <div>
      <Navbar />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Student Tools
      </Typography>
      <br />
      <Container>
        <Description variant="h6">
          These are helpful links for students in the class to use. They include
          links to guides, certifications, Bloomberg, and Excel files for
          in-class use.
        </Description>
        <ToolGrid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <StyledListHeader variant="h5" width="40%">
              Data
            </StyledListHeader>
            <List>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/bloomberg"
                    target="_blank"
                  >
                    Bloomberg Terminal
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/bloomberg/Certification"
                    target="_blank"
                  >
                    Bloomberg Certification
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://resourceaccess.lib.byu.edu/resources/capital-iq/"
                    target="_blank"
                  >
                    Capital IQ
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://www.capitaliq.com/ciqdotnet/downloads/downloads.aspx"
                    target="_blank"
                  >
                    Capital IQ Excel Plugin
                  </StyledLink>
                </ToolItem>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledListHeader variant="h5" width="100%">
              Background Research &#38; News
            </StyledListHeader>
            <List>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/pq-business"
                    target="_blank"
                  >
                    ProQuest One Business
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://dbs.lib.byu.edu/factiva"
                    target="_blank"
                  >
                    Factiva
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/company/articles"
                    target="_blank"
                  >
                    Articles &#38; News Guide
                  </StyledLink>
                </ToolItem>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledListHeader variant="h5" width="60%">
              Industry Ratios
            </StyledListHeader>
            <List>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/bizminer"
                    target="_blank"
                  >
                    BizMiner
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://dbs.lib.byu.edu/rma"
                    target="_blank"
                  >
                    RMA eStatement Studies
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/kbr"
                    target="_blank"
                  >
                    Mergent Key Business Ratios
                  </StyledLink>
                </ToolItem>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledListHeader variant="h5" width="60%">
              Analyst Reports
            </StyledListHeader>
            <List>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://dbs.lib.byu.edu/morningstar"
                    target="_blank"
                  >
                    Morningstar
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://dbs.lib.byu.edu/pq-jp-morgan"
                    target="_blank"
                  >
                    J.P. Morgan Research Reports
                  </StyledLink>
                </ToolItem>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledListHeader variant="h5" width="60%">
              Database Help
            </StyledListHeader>
            <List>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://guides.lib.byu.edu/company/troubleshooting"
                    target="_blank"
                  >
                    Database Troubleshooting Guide
                  </StyledLink>
                </ToolItem>
              </ListItem>
              <ListItem>
                <ToolItem disableTypography={true}>
                  <StyledLink
                    href="https://byu.sharepoint.com/sites/silverfund-wiki"
                    target="_blank"
                  >
                    Silver Fund Class Wiki
                  </StyledLink>
                </ToolItem>
              </ListItem>
            </List>
          </Grid>
        </ToolGrid>
      </Container>
      <br />
      <Footer />
    </div>
  );
}

export default Tools;
