import React from "react";
import SwipeableViews from "react-swipeable-views";
import styled from "@emotion/styled";
import {
  Typography,
  Container,
  Grid,
  Box,
  MobileStepper,
  Button,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import netTrekCandlestick from "../images/net_trek/net_trek_candlestick.jpg";
import netTrekAQR from "../images/net_trek/net_trek_aqr.jpg";
import netTrekSilverPoint from "../images/net_trek/net_trek_silver_point.jpg";
import netTrekInvesco from "../images/net_trek/net_trek_invesco.jpg";
import netTrekCynosure from "../images/net_trek/net_trek_cynosure.jpg";

const images = [
  {
    label: "Candlestick Capital (Craig Bench)",
    imgPath: netTrekCandlestick,
  },
  {
    label: "AQR (Dan Villalon)",
    imgPath: netTrekAQR,
  },
  {
    label: "Silverpoint (JT Davis and Derek Staples)",
    imgPath: netTrekSilverPoint,
  },
  {
    label: "Invesco (Megan Byrne, Beau Fuchs, Eliot Honaker)",
    imgPath: netTrekInvesco,
  },
  {
    label: "Cynosure Group (Brian Smedley)",
    imgPath: netTrekCynosure,
  },
];

export function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 600, flexGrow: 1, marginTop: "15%" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  maxHeight: 600,
                  display: "block",
                  maxWidth: 600,
                  overflow: "hidden",
                  width: "100%",
                  borderRadius: "16px",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="large"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export function Article(props) {
  return (
    <Container>
      <Typography variant="h4" sx={{ textAlign: "center", marginTop: "2em" }}>
        {props.title}
      </Typography>
      <Grid container spacing={6} sx={{ marginBottom: "6em" }}>
        <Grid item xs={12} md={6}>
          <img
            alt=""
            src={props.image}
            width="100%"
            style={{ borderRadius: "16px", marginTop: "1em" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{ marginBottom: "1em", textAlign: "center" }}
          >
            {props.date}
          </Typography>
          <Typography
            variant="h6"
            sx={{ marginBottom: "1em", textAlign: "center" }}
          >
            {props.body}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export const HeadLine = styled("div")({
  borderTop: "2px solid rgb(0, 42, 92)",
  marginTop: "2em",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
});

export const ArticleTitle = styled(Typography)({
  textAlign: "center",
  marginTop: "2em",
});

export const ArticleDate = styled(Typography)({
  marginBottom: "1em",
  textAlign: "center",
});
