import { Typography, Card, CardContent, styled } from "@mui/material";
import AtriumPic from "../images/atrium.png";

export function FactCard(props) {
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: "#fafafa",
        color: "#",
        height: "100%",
        borderColor: "#002E5D",
        borderRadius: "16px",
        borderWidth: "thick",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            marginBottom: ".25em",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {props.body}
        </Typography>
      </CardContent>
    </Card>
  );
}

function UnstyledTitle({ variant = "h3", className, children }) {
  return (
    <Typography variant={variant} className={className}>
      {children}
    </Typography>
  );
}

function UnstyledDescription({ variant = "h5", className, children }) {
  return (
    <Typography variant={variant} className={className}>
      {children}
    </Typography>
  );
}

export const Background = styled("div")`
  background-image: linear-gradient(
      180deg,
      rgba(0, 42, 92, 0.5) 0%,
      rgba(0, 42, 92, 1) 100%
    ),
    url(${AtriumPic});
  height: 100vh;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const FooterBackground = styled("div")`
  background-color: #c7c9c7;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
`;

export const Center = styled("div")`
  margin-top: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 200px;
  justify-content: center;
`;

export const DescriptionHead = styled(UnstyledTitle)`
  margin-top: 2em;
  margin-bottom: 1.5em;
  text-align: center;
`;

export const Description = styled(UnstyledDescription)`
  margin-bottom: 4em;
  text-align: center;
`;

export const BackgroundText = styled(Typography)`
  color: white;
  text-align: center;
`;
