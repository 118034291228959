import { API } from "aws-amplify";
import gql from "graphql-tag";

export const getAlumniInfo = async () => {
  const result = await API.graphql({
    query: gql`
      query listSilverFundAlumniInfos {
        listSilverFundAlumniInfos {
          items {
            name
            current_position
            current_company
            graduation_year
            profile_url
          }
        }
      }
    `,
    authMode: "API_KEY",
  });

  return result.data.listSilverFundAlumniInfos.items;
};
