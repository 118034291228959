import { Typography, Grid, Container } from "@mui/material";
import {
  SwipeableTextMobileStepper,
  HeadLine,
  ArticleTitle,
  ArticleDate,
} from "../components/NewsElements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Events() {
  return (
    <div>
      <Navbar />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Recent News
      </Typography>
      <Container>
        <HeadLine />
        <ArticleTitle variant="h4">Net Trek</ArticleTitle>
        <Grid container spacing={6} sx={{ marginBottom: "6em" }}>
          <Grid item xs={12} md={6}>
            <SwipeableTextMobileStepper />
          </Grid>
          <Grid item xs={12} md={6}>
            <ArticleDate variant="h6">05/2024</ArticleDate>
            <Typography variant="body1" sx={{ marginBottom: "1em" }}>
              Select students from the BYU Silver Fund team visited several
              asset management firms in New York and Connecticut following the
              2024 winter semester. The students represented a variety of
              academic disciplines including finance, accounting, math,
              economics, and computer science. Firm visits included
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>Candlestick Capital (Craig Bench)</li>
                <li>AQR (Dan Villalon)</li>
                <li>Silverpoint (JT Davis and Derek Staples)</li>
                <li>Invesco (Megan Byrne, Beau Fuchs, Eliot Honaker)</li>
                <li>Vontobel (Ramiz Chelat)</li>
                <li>Blackrock Systematic (Gerardo Rodriguez)</li>
                <li>Two Sigma (Ellie Kewene-Hite)</li>
                <li>Cynosure Group (Brian Smedley)</li>
                <li>Goldman Sachs Asset Management (Sebastian Gruhn)</li>
              </ul>
            </Typography>
            <Typography variant="body1">
              Each firm presented an info session on how the firm operates,
              hires, and invests. Students then had the privilege of engaging in
              insightful Q&A sessions. At Vontobel, three of our students,
              Hunter Blalack, Erik Christiansen, and Andrew Bevan, pitched a
              research report to company leaders on WNS Holdings. Students were
              also able to connect with BYU alumni at a hosted social event. The
              trip was organized by Silver Fund student leaders and supported by
              the BYU finance department.
            </Typography>
            <br />
            <Typography variant="body1">
              All of us on the Silver Fund Team are deeply grateful to all the
              firms and individuals who generously shared their expertise and
              made this enriching experience possible.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default Events;
