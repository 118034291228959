import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import Navbar from "../components/Navbar";
import ZachBancroft from "../images/zachary_bancroft.jpg";
import ErikChristiansen from "../images/erik_christiansen.jpg";
import BrendanPricer from "../images/brendan_pricer.jpg";
import BrandonWaits from "../images/brandon_waits.jpg";

function Team() {
  return (
    <div>
      <Navbar />
      <Typography variant="h3" align="center">
        Meet the Team
      </Typography>
      <br />
      <Container>
        <Typography variant="h6" align="left" style={{ marginBottom: "4em" }}>
          Meet the teams of the different funds. These include the
          undergraduate, graduate, and quantitative funds.
        </Typography>
        <Grid
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Undergraduate Fundamental
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              alt=""
              style={{
                maxWidth: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={BrendanPricer}
            />
            <Typography variant="h6" align="center">
              Brendan Pricer
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">
              Brendan is a senior in BYU's finance program, pursuing a career in
              fundamental equity analysis. He grew up in Corona, California, and
              was an avid baseball player throughout his childhood. After
              graduating high school, he served a two-year mission for The
              Church of Jesus Christ of Latter-day Saints in the UK. Upon
              returning, he married his high school sweetheart, who is also a
              senior at BYU. Currently, he is interning on the technology sector
              team at Grandeur Peak Global Advisors. He has been involved with
              Silver Fund/Brigham Capital for two years, and credits the
              organization for most of his early professional success.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Graduate Fundamental
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              alt=""
              style={{
                maxWidth: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={ZachBancroft}
            />
            <Typography variant="h6" align="center">
              Zachary Bancroft
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">
              Zachary is from Bountiful, UT, and he served a mission in the
              Philippines, Cauayan Mission. He is a second-year graduate student
              pursuing a master's in accountancy with a focus on tax (MAcc).
              Currently, he works as the Head Site Coordinator for BYU's VITA
              lab and as a tax preparer for Udall CPA Group. In his free time,
              he enjoys playing soccer, watching Utah Jazz games, and spending
              time with his wife. This is his second semester participating in
              the Silver Fund, and he is excited to help others get involved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              alt=""
              style={{
                maxWidth: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={ErikChristiansen}
            />
            <Typography variant="h6" align="center">
              Erik Christiansen
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">
              Erik is a second-year MBA student with an emphasis in finance. He
              hails from Las Vegas, NV and studied economics in his undergrad at
              BYU. This will be his second year in Silver Fund and he has loved
              every minute. He has also had the chance to intern for Ethos
              Investment Management and Grandeur Peak Global Advisors while
              being in school pursuing a career in equity research. In his spare
              time he enjoys playing soccer, cycling, and he is a competitive
              chess player.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Quantitative Team
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img
              alt=""
              style={{
                maxWidth: "50%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={BrandonWaits}
            />
            <Typography variant="h6" align="center">
              Brandon Waits
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">
              Brandon is currently studying computational mathematics, venturing
              to apply his quantitative skillset to financial markets. After
              working on the portfolio strategy team at Ensign Peak Advisors,
              Brandon hopes to pursue future opportunities in portfolio
              analytics, risk management, and quantitative investment research.
              In his free time, Brandon enjoys hiking in national parks and
              cooking with his wok.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Team;
