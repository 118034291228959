import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  Paper,
  Button,
} from "@mui/material";
import { InputTextField, TableHeaders } from "../components/AlumniElements";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LinkedInBlue from "../images/linkedin-blue-no-outline.png";
import { getAlumniInfo } from "../graphql/backend";

function Alumni() {
  const [alumniData, setAlumniData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [page, setPage] = useState(1);
  const perPage = 15;

  const filterData = useCallback(() => {
    const filtered = alumniData.filter(
      (row) =>
        row.name.toLowerCase().includes(name.toLowerCase()) &&
        row.current_position.toLowerCase().includes(position.toLowerCase()) &&
        row.current_company.toLowerCase().includes(company.toLowerCase())
    );
    setFilteredData(filtered);
    setPage(1);
  }, [alumniData, name, position, company]);

  useEffect(() => {
    getAlumniInfo()
      .then((data) => {
        data.sort((a, b) => {
          if (a.name.split(" ").at(-1) < b.name.split(" ").at(-1)) return -1;
          if (a.name.split(" ").at(-1) > b.name.split(" ").at(-1)) return 1;
          return 0;
        });
        setAlumniData(data);
        setFilteredData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const getPaginatedData = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return filteredData.slice(startIndex, endIndex);
  };

  return (
    <div>
      <Navbar />
      <br />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Alumni List
      </Typography>
      <br />
      <Container>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Below is a list of alumni who have been a part of Silver Fund. Feel
          free to reach out to any of the alumni below to learn more about their
          experience with Silver Fund and the finance industry. You are able to
          filter by name, position, and company.
        </Typography>
      </Container>
      <br />
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TableHeaders>Alumni</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Current Position</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Current Company</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Graduation Year</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>LinkedIn</TableHeaders>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ height: "20px" }}>
              <TableRow>
                <TableCell align="center">
                  <InputTextField
                    label="Name"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell align="center">
                  <InputTextField
                    label="Position"
                    variant="outlined"
                    onChange={(e) => setPosition(e.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell align="center">
                  <InputTextField
                    label="Company"
                    variant="outlined"
                    onChange={(e) => setCompany(e.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {getPaginatedData().map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.current_position}</TableCell>
                  <TableCell align="center">{row.current_company}</TableCell>
                  <TableCell align="center">{row.graduation_year}</TableCell>
                  <TableCell align="center">
                    <a href={row.linkedin} target="_blank" rel="noreferrer">
                      <img
                        src={LinkedInBlue}
                        alt="LinkedIn"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="body1">
            Page {page} of {Math.ceil(filteredData.length / perPage)}
          </Typography>
          <br />
          <Typography variant="body1">
            <Button
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
              style={{
                marginRight: "10px",
                backgroundColor: "rgb(0, 42, 92)",
                color: "white",
              }}
              variant="contained"
              size="small"
            >
              Previous
            </Button>
            <Button
              onClick={() => setPage(page + 1)}
              disabled={page === Math.ceil(filteredData.length / perPage)}
              style={{ backgroundColor: "rgb(0, 42, 92)", color: "white" }}
              variant="contained"
              size="small"
            >
              Next
            </Button>
          </Typography>
        </Container>
      </Container>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Alumni;
