import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FooterBackground } from "./HomeElements";
import { Item } from "./NavbarElements";
import "../styles/styles.css";
import InLogo from "../images/linkedin-blue-no-outline.png";

export default function Footer() {
  const StyledLink = styled("a")`
    text-decoration: none;
    color: #0072ce;
    &:focus,
    &:hover {
      opacity: 0.8;
    }
    &:visited,
    &:link,
  `;

  const StyledStrong = styled("strong")`
    color: rgba(0, 42, 92, 1);
    text-decoration: none;
  `;

  return (
    <FooterBackground style={{ paddingTop: "25px", paddingBottom: "25px" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="baseline"
      >
        <Grid item>
          <StyledStrong>contact</StyledStrong>
          <Item>
            <StyledLink href="http://marriott.byu.edu/directory/details?id=17907">
              Brandon Bates
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="http://boyer.byu.edu/">Brian Boyer</StyledLink>
          </Item>
          <Item>
            <StyledLink href="https://marriott.byu.edu/directory/details?id=90202">
              James Fletcher
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="https://marriott.byu.edu/directory/details?id=24423">
              Ian Wright
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="mailto:silverfund@byu.edu">
              silverfund@byu.edu
            </StyledLink>
          </Item>
        </Grid>
        <Grid item>
          <StyledStrong>quick links</StyledStrong>
          <Item>
            <StyledLink href="https://byu.sharepoint.com/sites/silverfund-wiki">
              Silver Fund Class Wiki
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="https://marriott.byu.edu/mba/career/accelerated-learning/silver-fund/">
              Marriott School Class Info
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="https://marriott.byu.edu/">
              Marriott School Home
            </StyledLink>
          </Item>
          <Item>
            <StyledLink href="https://byu.edu/">BYU Home</StyledLink>
          </Item>
        </Grid>
        <Grid item columns={8}>
          <StyledStrong>connect</StyledStrong>
          <Item justifyContent="center">
            <StyledLink href="https://www.linkedin.com/company/byu-silver-fund/">
              <img
                src={InLogo}
                alt=""
                style={{ paddingTop: "5px", width: "20px" }}
              ></img>
            </StyledLink>
          </Item>
          <Item justifyContent="center">
            <StyledLink
              href="https://twitter.com/byusilverfund"
              style={{ fontSize: "21px", color: "black" }}
            >
              𝕏
            </StyledLink>
          </Item>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          paddingTop: "25px",
          color: "rgb(0, 42, 92)",
        }}
      >
        © 2023 Silver Fund | All Rights Reserved
      </Typography>
    </FooterBackground>
  );
}
