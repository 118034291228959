import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import {
  NavMenu,
  Bars,
  NavLink,
  Container,
  ByuLogo,
  SfLogo,
  Title,
  Item,
} from "./NavbarElements";
import { Button } from "@mui/material";

export default function Navbar(props) {
  const history = useHistory();
  const [showNav, setShowNav] = React.useState(false);

  const handleClick = () => {
    setShowNav(!showNav);
  };

  return (
    <Container>
      <Item>
        <SfLogo dark={props.dark} onClick={() => history.push("/")}></SfLogo>
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          onClick={() => history.push("/")}
        >
          <ByuLogo dark={props.dark}></ByuLogo>
          <Title dark={props.dark}> Silver Fund </Title>
        </div>
        <Bars onClick={handleClick} dark={props.dark} />
      </Item>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NavMenu show={showNav} dark={props.dark}>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/events">News</NavLink>
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/positions">Top Holdings</NavLink>
          <NavLink to="/alumni">Alumni</NavLink>
          <NavLink to="/tools">Tools</NavLink>
          <NavLink to="/history">History</NavLink>
          <a
            href="https://47fund.byu.edu"
            style={{
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              style={{
                color: props.dark ? "#002A5C" : "white",
                backgroundColor: props.dark ? "white" : "#002A5C",
              }}
            >
              Login
            </Button>
          </a>
        </NavMenu>
      </Grid>
    </Container>
  );
}
