import Navbar from "../components/Navbar";
import { Background, BackgroundText, Center } from "../components/HomeElements";

export default function Home(props) {
  return (
    <div>
      <Background>
        <Navbar dark></Navbar>
        <Center>
          <BackgroundText variant="h3">
            Seeking Alpha Through <br /> Fundamental and Quantitative Analysis
          </BackgroundText>
        </Center>
      </Background>
    </div>
  );
}
