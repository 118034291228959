import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import { styled, Grid, Typography, Menu } from "@mui/material";
import byuLogoWhite from "../images/byu-logo-text-white.png";
import byuLogoBlue from "../images/byu-logo-text-blue.png";
import sfLogoWhite from "../images/sf-logo-white.png";
import sfLogoBlue from "../images/sf-logo-blue.png";

export const NavMenu = styled("div", { shouldForwardProp: false })`
  display: flex;
  flex-wrap: wrap;
  font-size: 1.3em;
  color: ${(props) => (props.dark ? "white" : "black")};
  & a {
    color: ${(props) => (props.dark ? "white" : "black")};
  }
  @media (max-width: 768px) {
    display: ${(props) => (!props.show === true ? "none" : "flex")};
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: ${(props) => (props.dark ? "white" : "#002A5C")};
  float: right;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.8rem;
    cursor: pointer;
    width: 25%;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.2rem 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  &.active {
    color: #000000;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Container = styled(Grid)`
  align-items: center;
  color: ${(props) => (props.dark ? "#d1d1d1" : "black")};
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
  min-height: 80px;
  margin: 1em;
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  min-width: 80%;
  cursor: pointer;
`;

function UnstyledByuLogo({ dark, className, children }) {
  return (
    <img
      className={className}
      src={dark ? byuLogoWhite : byuLogoBlue}
      alt=""
    ></img>
  );
}

export const ByuLogo = styled(UnstyledByuLogo)`
  width: 100px;
  object-fit: contain;
`;

function UnstyledSfLogo({ dark, className, children }) {
  return (
    <img
      className={className}
      src={dark ? sfLogoWhite : sfLogoBlue}
      alt=""
    ></img>
  );
}

export const SfLogo = styled(UnstyledSfLogo)`
  width: 100px;
  object-fit: contain;
`;

const Title = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  color: ${(props) => (props.dark ? "white" : "#002E5D")};
  @media (min-width: 528px) {
    align-self: center;
    margin-left: 0.25em;
  }
`;
Title.defaultProps = {
  variant: "h3",
};

export { Title };

export const Item = styled(Grid, { Item: true })`
  display: flex;
`;

export const A = styled("a")`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  cursor: pointer;
  &.active {
    color: #000000;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(0, 42, 92)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "rgb(0, 42, 92)",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, .04)",
        opacity: 0.5,
      },
    },
  },
}));
