import Navbar from "../components/Navbar";
import "../styles/styles.css";
import { Container, Grid, Typography } from "@mui/material";
import Item from "@mui/material/Grid";
import Footer from "../components/Footer";
import Harold from "../images/HaroldSilver.png";

function History() {
  return (
    <div>
      <Navbar />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Harold F. Silver
      </Typography>
      <br />
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        Founder of BYU Silver Fund in 1989
      </Typography>
      <br />
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Typography variant="body1">
                Harold F. Silver, a Salt Lake native, started Silver Fund in
                1989 by donating $274,000 dollars to Brigham Young University
                for university scholarships. The fund has been since used as a
                tool to teach students the principles of investing.
              </Typography>
              <br />
              <Typography variant="body1">
                Harold F. Silver was a noted inventor and industrialist. One of
                his many inventions was the continuous mining machine, which
                revolutionized coal mining. Because of that, he was cited by
                Time-Life Books as one of the 250 greatest inventors in history.
                But his impact also extended beyond industry. A dedicated
                philanthropist, Silver gave generously to educational
                institutions, including the endowment of scholarships at Brigham
                Young University.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item xs={12}>
              <Typography variant="h4">Harold F. Silver</Typography>
            </Item>
            <br />
          </Grid>
          <Grid item xs={4}>
            <Item xs={12}>
              <img src={Harold} alt="" style={{ width: "100%" }}></img>
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item xs={12}>
              <Typography variant="body1" style={{ marginTop: "-20px" }}>
                <p>
                  A son of Joseph A. and Elizabeth Farnes Silver, he was born in
                  Salt Lake City on March 15, 1901. He attended Riverside
                  Elementary School and LDS High School. He studied at Columbia
                  University, New York City, then took pre-law courses at the
                  University of Utah, Salt Lake City.
                </p>
                <p>
                  At the age of 21, Mr. Silver became chief engineer of the
                  Ogden Iron Works. He invented a non-flammable dry cleaning
                  process, sugar beet piling equipment, the continuous coal
                  mining machine, the first commercially successful continuous
                  diffuser for sugar beets, the first modern continuous cane
                  sugar diffuser, the continuous centrifugal cone press, and the
                  coal drier.
                </p>
                <p>
                  After designing the sugar beet piling machinery, Mr. Silver
                  moved his family to Denver in 1934. He founded the
                  Silver-Roberts Iron Works with Fred Roberts. In 1940, he
                  bought out his partner and changed the company's name to
                  Silver Engineering Works.
                </p>
              </Typography>
            </Item>
          </Grid>
          <Grid xs={12}>
            <Item xs={12}>
              <Typography variant="body1" style={{ marginLeft: "15px" }}>
                <p>
                  Later he formed Silver Steel Co., a steel and aluminum
                  distribution network throughout the Mountain West, with a
                  plant in Salt Lake City.
                </p>
                <p>
                  After selling Silver Engineering Works and Silver Steel
                  (divisions of the Silver Corp.) to Amfac of Hawaii in 1965, he
                  developed a computerized stock evaluation program called
                  Markedex.
                </p>
                <p>
                  Mr. Silver was a prominent Denver civic leader. He was
                  chairman of the Denver Area Community Chest and of the Area
                  Council, Boy Scouts of America. He was the honorary vice
                  chairman of the Mile High United Fund.
                </p>
                <p>
                  He received many awards for community service, including the
                  Jesse Knight Industrial Citizenship Award by BYU, the Mile
                  High Sertoma Club Serve to Mankind Award in 1961, the Regis
                  College “Civis Princeps” (first citizen) medal, the National
                  Conference of Christians and Jews Brotherhood Award, the 1974
                  public service award of the Colorado Association of Commerce
                  and Industry and the Denver Clinic 1978 distinguished service
                  award.
                </p>
                <p>
                  He endowed the Harold F. Silver Chair of Finance and
                  Management in BYU’s School of Management. He established the
                  Madelyn Stewart Silver and Ruth Smith Silver Fund for student
                  scholarships at BYU.
                </p>
                <p>Deseret News September 1984 (with modification)</p>
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default History;
