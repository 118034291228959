import styled from "styled-components";
import { Typography, Grid, ListItemText } from "@mui/material";

export const StyledLink = styled("a")`
text-decoration: none;
color: #0072ce;
&:focus,
&:hover {
  opacity: 0.8;
}
&:visited,
&:link,
`;

export const StyledListHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "width",
})`
  text-align: center;
  background-color: rgb(0, 42, 92);
  color: white;
  font-weight: bold;
  border-radius: 16px;
  padding: 5px;
  width: ${(props) => props.width || "100%"};
  margin: auto;
`;

export const ToolGrid = styled(Grid)({
  marginTop: "20px",
  alignItems: "flex-start",
  justifyContent: "center",
});

export const ToolItem = styled(ListItemText)({
  textAlign: "center",
  fontWeight: "bold",
});
