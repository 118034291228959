import styled from "styled-components";
import { Typography, Grid, ListItemText } from "@mui/material";

function UnstyledDescription({ variant = "h6", className, children }) {
  return (
    <Typography variant={variant} className={className}>
      {children}
    </Typography>
  );
}

export const CompanyCloud = styled("img")`
  display: block;
  margin: auto;
  max-width: 80%;
  margin-bottom: 4em;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const StyledLink = styled("a")`
text-decoration: none;
color: #0072ce;
&:focus,
&:hover {
  opacity: 0.8;
}
&:visited,
&:link,
`;

export const ProfessorGrid = styled(Grid)`
  margin-top: 20px;
  justify-content: center;
  align-items: center;
`;

export const ProfessorImage = styled("img")`
  max-width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ClassesListText = styled(ListItemText)`
  text-align: left;
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
`;

export const AboutBody = styled(UnstyledDescription)`
  margin-bottom: 4em;
  text-align: left;
`;

export const AboutSubHeader = styled(UnstyledDescription)`
  text-align: center;
`;
