import {
  Typography,
  Container,
  List,
  Box,
  ListItem,
  Grid,
} from "@mui/material";
import {
  CompanyCloud,
  StyledLink,
  ProfessorImage,
  ClassesListText,
  AboutBody,
  AboutSubHeader,
  ProfessorGrid,
} from "../components/AboutElements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import companyCloud from "../images/company_cloud_new.png";
import "../styles/styles.css";

function About() {
  return (
    <div>
      <Navbar />
      <AboutSubHeader variant="h3">About Us</AboutSubHeader>
      <br />
      <Container>
        <AboutBody>
          Silver Fund is among the nation's oldest and largest student-run
          investment funds. Silver Fund is run by BYU students in finance,
          accounting, economics, and math with advisement from faculty in the
          Marriott School Finance Department. The organizing principle is that
          it's a student-run fund, and that idea suffuses the experience.
          Students do the research. Students make the investment decisions.
          Students own the results. Many of our alumni have gone on to become
          leaders in the finance industry.
        </AboutBody>
        <AboutSubHeader variant="h4">Recent Student Placements</AboutSubHeader>
        <CompanyCloud
          src={companyCloud}
          alt="Companies that Silver Fund alumni have gone on to work for"
        />
        <AboutSubHeader variant="h4">Classes</AboutSubHeader>
        <AboutBody>
          There are 3 different funds/classes: an undergraduate fundamental fund
          (composed primarily of students in finance, economics, and
          accounting), a graduate fundamental fund (primarily composed of MBA
          and MAcc students), and a quant fund (primarily composed of students
          from economics, finance, math, and computer science). All majors are
          welcome to apply. All student applications are evaluated by the
          supervising faculty which include Brian Boyer, Ian Wright, James
          Fletcher, and Brandon Bates.
        </AboutBody>
        <AboutSubHeader variant="h4">How to Apply</AboutSubHeader>
        <AboutBody>
          Participation in the class generally requires a commitment of two
          semesters. An information session is organized for interested
          applicants approximately one month prior to the conclusion of both the
          Fall and Winter semesters. During this info session, a digital
          application form is made available. Announcements about the
          information session are disseminated through multiple channels within
          the Finance Society, Economics, and Math departments.
        </AboutBody>
        <AboutSubHeader variant="h4">
          Recommended Classes/Experience
        </AboutSubHeader>
        <Box>
          <List>
            <ListItem alignItems="flex-start">
              <ClassesListText
                primary="Undergraduate Fund - Brigham Capital, Finance 410 (Investments)"
                disableTypography={true}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ClassesListText
                primary="Graduate Fund - MBA 622 (Investments)"
                disableTypography={true}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ClassesListText
                primary="Quantitative Fund - Finance 585R (Pre-PhD Finance Seminar)"
                sx={{ marginBottom: "40px" }}
                disableTypography={true}
              />
            </ListItem>
          </List>
        </Box>
        <AboutSubHeader variant="h4">Faculty Advisors</AboutSubHeader>
        <ProfessorGrid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <ProfessorImage
              src="https://marriott.byu.edu/msmadmin/securefile/empphoto?pid=17907"
              alt="Brandon Bates"
            />
            <AboutSubHeader>Brandon Bates</AboutSubHeader>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">
              Brandon Bates is a seasoned portfolio manager for global macro,
              equity, and volatility hedge fund strategies. In his present role
              as Head of Systematic Macro at Magnetar Capital, he focuses on
              quantitative investment strategies across all asset classes that
              leverage fundamental economic insights and exploit persistent
              market frictions and behavioral biases. His efforts join data
              science methods with economic theory. Previously, he was a member
              of the hedge fund portfolio management teams at Squarepoint
              Capital and BlackRock (legacy Barclays Global Investors team). He
              is an advisor for Cambridge University's MFin Practicum on
              foreign-exchange and volatility investing and a frequent guest
              lecturer at the Yale School of Management teaching active foreign
              exchange investing. Brandon received AM and PhD degrees in
              financial economics from Harvard University.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProfessorImage
              src="https://marriott.byu.edu/msmadmin/securefile/empphoto?pid=5290"
              alt="Brian Boyer"
            />
            <AboutSubHeader>Brian Boyer</AboutSubHeader>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">
              Brian Boyer is an associate professor of finance at Brigham Young
              University. His research covers a broad range of topics including
              style investing and index effects, financial contagion,
              preferences for lottery-like assets in stocks and options, the
              performance of private equity in secondary markets, and the
              relative performance and estimation of factor models. His papers
              have appeared in leading academic journals including the Journal
              of Finance and the Review of Financial Studies. He helped
              establish and continues to organize the annual{" "}
              <StyledLink href="https://marriott.byu.edu/event/redrock2024/">
                BYU Red Rock Conference
              </StyledLink>{" "}
              a respected academic gathering that brings together leading
              researchers in financial economics each year. The conference has
              been recognized among the top finance conferences in terms of
              academic quality. Brian holds a PhD in financial economics from
              the University of Michigan.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProfessorImage
              src="https://marriott.byu.edu/msmadmin/securefile/empphoto?pid=90202"
              alt="James Fletcher"
            />
            <AboutSubHeader>James Fletcher</AboutSubHeader>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">
              James Fletcher is an experienced Emerging Markets portfolio
              manager with a strong history of top quartile fund performance. He
              is the founder and Chief Investment Officer of Ethos Investment
              Management. Previously, James was based in Hong Kong as the
              Director & Senior Portfolio Manager of the EM SMID Cap fund at APG
              Asset Management, one of the largest EM SMID cap funds in the
              world. James was previously the Lead Portfolio Manager at Kayne
              Anderson Rudnick's EM Small Cap fund and worked as a Senior
              Analyst at Westwood Global Investments. James is Founder and
              President of the Board of Directors for{" "}
              <StyledLink href="https://yis.org/">
                Young Investors Society
              </StyledLink>{" "}
              which serves over 3000 high schools around the world. James is a
              frequent poster on{" "}
              <StyledLink href="https://www.linkedin.com/in/james-fletcher-cfa-7a280068/">
                LinkedIn
              </StyledLink>{" "}
              with thousands of followers. He holds a BA degree in finance from
              Brigham Young University and is a CFA charter holder.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ProfessorImage
              src="https://marriott.byu.edu/msmadmin/securefile/empphoto?pid=24423"
              alt="Ian Wright"
            />
            <AboutSubHeader>Ian Wright</AboutSubHeader>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">
              Ian Wright is an assistant professor in the finance department at
              Brigham Young University where he is the undergraduate finance
              program director. He is also a visiting professor at the
              University of Chicago, where he teaches macro finance in the
              masters of financial math program. Prior to coming to BYU, he was
              a quantitative macro investor in a £20bn fund at BlackRock in
              London, where he invested across asset classes and regions.
              Preceding that he was an Executive Director in the asset
              allocation research group in the macro department at Goldman Sachs
              in London. Ian graduated with his Ph.D. from the Department of
              Economics at Stanford University, where he served as a research
              assistant to the Working Group on Economic Policy at the Hoover
              Institution and was a co-editor of the book Government Policies
              and the Delayed Economic Recovery.
            </Typography>
          </Grid>
        </ProfessorGrid>
        <AboutSubHeader variant="h4" sx={{ marginTop: "20px" }}>
          Interested in Joining?
        </AboutSubHeader>
        <AboutSubHeader>
          Reach out to any of the faculty advisors or student leaders. Silver
          fund is always looking for new members.
        </AboutSubHeader>
        <br />
      </Container>
      <br />
      <Footer />
    </div>
  );
}

export default About;
